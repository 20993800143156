import { useEffect } from 'react';
import Router from 'next/router';

const Index = () => {
  useEffect(() => {
    Router.push('/quote/product');
  }, []);

  return null;
};

export default Index;
